// Footer.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#000000', color: 'white', marginTop: '20px', padding: '20px 0' }}>
      <Container>
        <Row>
          <Col className="text-center">
            <p>Contact Us: info@nirvanajewels.org</p>
            <p>Address: 606 S Hill Street, Los Angeles, CA 90014</p>
          </Col>
          <Col className="text-center">
            <a href="/faq" style={{ color: 'white', textDecoration: 'none', marginRight: '10px' }}>FAQ</a>
            <a href="/terms-and-conditions" style={{ color: 'white', textDecoration: 'none' }}>Terms and Conditions</a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;