// Login.js
import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import './FormStyles.css';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user && user.emailVerified) {
        navigate('/'); // Navigate to the home page if already logged in and email is verified
      }
    });

    return unsubscribe;
  }, [navigate, auth]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      if (!user.emailVerified) {
        setError('Your email address has not been verified.');
      } else {
        navigate('/profile-completion'); // Redirect to profile completion if email verified
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="form-container">
      <h2 className="text-center mb-4" style={{ color: '#000000' }}>Log In</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      <form onSubmit={handleSubmit}>
        <div className="input-group">
            <label>Email address</label>
            <input type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </div>

        <div className="input-group">
            <label>Password</label>
            <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        </div>

        <Button variant="primary" type="submit" style={{ backgroundColor: '#000000', borderColor: '#000000' }}>
          Log In
        </Button>
      </form>
    </div>
  );
}

export default Login;
