// Import Firebase Storage functions
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

// Initialize Firebase Storage
const storage = getStorage();

export const fetchShapeData = async () => {
  try {
    // Reference to your CSV file in Firebase Storage
    const storageRef = ref(storage, 'stock-data/data.csv');
    const url = await getDownloadURL(storageRef);

    // Fetch CSV data from the URL
    const response = await fetch(url);
    const text = await response.text();
    const rows = text.split('\n').slice(1);
    const tempShapes = {};

    rows.forEach(row => {
      const columns = row.split(',');
      if (columns[0]) {
        const shape = columns[1]?.trim();
        const details = {
          type: columns[0].trim(),
          stockId: columns[2].trim(),
          measurements: columns[3]?.trim(),
          size: columns[4]?.trim(),
          length: columns[5]?.trim(),
          breadth: columns[6]?.trim(),
          ratio: columns[7]?.trim(),
          color: columns[8]?.trim(),
          clarity: columns[9]?.trim(),
          pricePerCarat: parseFloat(columns[10])
        };

        if (!tempShapes[shape]) {
          tempShapes[shape] = {
            name: shape,
            details: [],
          };
        }

        tempShapes[shape].details.push(details);
      }
    });

    return Object.values(tempShapes);
  } catch (error) {
    console.error("Error fetching shape data:", error);
    return [];
  }
};
