import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap'; // Import Container
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc } from 'firebase/firestore';
// Import your page components
import Home from './pages/Home';
import Purchase from './pages/Purchase';
import Contact from './pages/Contact';
import Cart from './pages/Cart';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import ProfileCompletion from './pages/ProfileCompletion';
import OrderComplete from './pages/OrderComplete';
import './App.css';
import Footer from './Footer';
import Account from './pages/Account';
import { AdminConsole } from './pages/AdminConsole';
import Inquiry from './pages/Inquiry';
import DiamondViewerWidget from './pages/DiamondViewerWidget';
import Details from './pages/Details';
import Invoice from './pages/Invoice'; // Import the Invoice component
import NavBar from './Navbar'; // Import the NavBar component
import { Helmet } from 'react-helmet';

function App() {
  const [user, setUser] = useState(null);
  const [userProfileCompleted, setUserProfileCompleted] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    const db = getFirestore();
    const unsubscribe = onAuthStateChanged(auth, async currentUser => {
      setUser(currentUser);
      if (currentUser) {
        const docRef = doc(db, "users", currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setUserProfileCompleted(docSnap.data().profileCompleted);
        }
      }
    });

    return unsubscribe; // Cleanup subscription on unmount
  }, []);

  return (
    <Router>
      <div className="d-flex flex-column min-vh-100">
        <Helmet>
          <title>Nirvana Jewels</title>
        </Helmet>
        <NavBar user={user} userProfileCompleted={userProfileCompleted} />
        <Container className="flex-grow-1" style={{ paddingTop: '20px' }}>
          <Routes>
            <Route path="/diamond-viewer-widget" element={<DiamondViewerWidget />} />
            <Route path="/" element={<Home />} />
            <Route path="/purchase" element={<Purchase />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/inquire" element={<Inquiry />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/profile-completion" element={<ProfileCompletion />} />
            <Route path="/order-complete" element={<OrderComplete />} />
            <Route path="/account" element={<Account />} />
            <Route path="/admin" element={<AdminConsole />} />
            <Route path="/details/:transactionId" element={<Details />} />
            <Route path="/invoices/:transactionId" element={<Invoice />} /> {/* Add the new route */}
          </Routes>
        </Container>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
