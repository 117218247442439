import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';

function OrderComplete() {
    const location = useLocation();
    const transactionId = location.state?.transactionId;

    if (!transactionId) {
        return <Navigate to="/" />;
    }

    return (
        <Container>
            <style type="text/css">
                  {`
                      .order-complete-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100vh; /* Make the container take up the full screen height */
                        background-color: #f8f8f8; /* Light background color */
                    }
                    
                    .order-complete-card {
                        max-width: 500px;
                        margin: auto;
                        padding: 20px;
                        background-color: #fff;
                        border-radius: 8px;
                        box-shadow: 0 4px 8px rgba(0,0,0,0.1);
                        border: 5px solid #600020; /* Hard dark maroon edge to match the cart page */
                    }
                    
                    .order-details p {
                        color: #333; /* Dark text for readability */
                        margin-bottom: 15px;
                    }
                    
                    button {
                        background-color: #400015; /* Dark maroon button */
                        border: none;
                        color: white;
                        padding: 10px 15px;
                        border-radius: 5px;
                        display: block; /* Ensure the button fills the container width */
                        width: 100%;
                    }
                    
                    button:hover {
                        background-color: #300010; /* Darken the button on hover for feedback */
                    }
                  `}
              </style>
        <div className="order-complete-container">

            <Container className="order-complete-card">
                <h2>Order Complete</h2>
                <div className="order-details">
                    <p>Transaction ID: {transactionId}</p>
                    <p>Estimated Delivery: Tomorrow</p>
                    {/* Add more invoice details as needed */}
                </div>
                <Button href="/" variant="success">Return Home</Button>
            </Container>
        </div>
        </Container>
    );
}

export default OrderComplete;
