import React, { useRef, useState, useEffect } from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { TransformControls, OrbitControls } from '@react-three/drei';

const Cube = ({ position, scale, isSelected, onSelect, onMove }) => {
    const meshRef = useRef();
  
    // Call onMove whenever the mesh position changes
    useEffect(() => {
      if (meshRef.current) {
        meshRef.current.position.set(...position);
      }
    }, [position, onMove]);
  
    return (
      <mesh
        position={position}
        scale={scale}
        ref={meshRef}
        onClick={(e) => {
          e.stopPropagation(); // Prevent event from reaching OrbitControls
          onSelect();
        }}>
        <boxGeometry />
        <meshStandardMaterial color={isSelected ? 'skyblue' : 'orange'} />
      </mesh>
    );
  };

const CameraController = ({ cameraPosition }) => {
  const { camera } = useThree();
  useEffect(() => {
    camera.position.set(...cameraPosition);
    camera.lookAt(0, 0, 0);
    camera.updateProjectionMatrix();
  }, [camera, cameraPosition]);

  return null;
};

function DiamondViewerWidget() {
    const [cubes, setCubes] = useState([]);
    const [selectedCubeIndex, setSelectedCubeIndex] = useState(null);
    const [cameraPosition, setCameraPosition] = useState([0, 0, 10]);
  
    const addCube = () => {
      const position = [Math.random() * 2 - 1, Math.random() * 2 - 1, Math.random() * 2 - 1];
      const scale = 1 + Math.random(); // Slightly larger cubes
      setCubes([...cubes, { position, scale }]);
    };
  
    const removeCube = () => {
      if (selectedCubeIndex !== null) {
        let newCubes = [...cubes];
        newCubes.splice(selectedCubeIndex, 1);
        setCubes(newCubes);
        setSelectedCubeIndex(null); // Deselect cube when removed
      }
    };
  
    // Update cube position in state
    const handleCubeMove = (index, newPosition) => {
      let newCubes = [...cubes];
      newCubes[index] = { ...newCubes[index], position: newPosition };
      setCubes(newCubes);
    };

  return (
    <Container className="my-4">
    <Row>
        <Col className="text-center">
          <Button variant="primary" onClick={addCube}>Add Cube</Button>
          <Button variant="danger" onClick={removeCube} className="ms-2">Remove Cube</Button>
          <Button onClick={() => setCameraPosition([0, 0, 10])} className="ms-2">Front View</Button>
          <Button onClick={() => setCameraPosition([10, 0, 0])} className="ms-2">Side View</Button>
          <Button onClick={() => setCameraPosition([0, 10, 0])} className="ms-2">Top View</Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Canvas style={{ width: '100%', height: '80vh' }}>
            <ambientLight intensity={0.5} />
            <spotLight position={[10, 15, 10]} angle={0.3} />
            {cubes.map((cube, index) => (
              <Cube
                key={index}
                position={cube.position}
                scale={[cube.scale, cube.scale, cube.scale]}
                isSelected={selectedCubeIndex === index}
                onSelect={() => setSelectedCubeIndex(index)}
                onMove={(newPosition) => handleCubeMove(index, newPosition.toArray())}
              />
            ))}
            {selectedCubeIndex !== null && (
              <TransformControls
                mode="translate"
                position={cubes[selectedCubeIndex].position}
                onObjectChange={(e) => handleCubeMove(selectedCubeIndex, e.target.position)}
              />
            )}
            <OrbitControls enablePan={true} enableZoom={true} enableRotate={true} />
          </Canvas>
        </Col>
      </Row>
    </Container>
  );
}

export default DiamondViewerWidget;