// ProfileCompletion.js
import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';

function ProfileCompletion() {
  const [businessId, setBusinessId] = useState('');
  const [businessLegalName, setBusinessLegalName] = useState('');
  const [howDidYouHearAboutUs, setHowDidYouHearAboutUs] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [address, setAddress] = useState('');
  const navigate = useNavigate();
  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const db = getFirestore();
    if (!auth.currentUser || !auth.currentUser.emailVerified) {
      navigate('/login'); // Redirect to login if user is not logged in or email not verified
    } else {
      const docRef = doc(db, "users", auth.currentUser.uid);
      getDoc(docRef).then(docSnap => {
        if (docSnap.exists() && docSnap.data().profileCompleted) {
          navigate('/'); // Redirect to home if profile is already completed
        }
      });
    }
  }, [navigate, auth]);

  useEffect(() => {
    if (!auth.currentUser || !auth.currentUser.emailVerified) {
      navigate('/login'); // Redirect to login if user is not logged in or email not verified
    }
  }, [navigate, auth]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
  
    try {
        const uid = auth.currentUser.uid;
        await setDoc(doc(db, "users", uid), {
          businessId,
          businessLegalName,
          howDidYouHearAboutUs,
          profileCompleted: true,
          phone,
          address,
          zipCode,
        }, { merge: true });
        navigate('/');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="form-container">
      <h2>Complete Your Profile</h2>
      {error && <div className="error">{error}</div>}
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <label htmlFor="businessId">Business ID</label>
          <input type="text" id="businessId" placeholder="Enter Business ID" value={businessId} onChange={(e) => setBusinessId(e.target.value)} required />
        </div>

        <div className="input-group">
          <label htmlFor="businessLegalName">Business Legal Name</label>
          <input type="text" id="businessLegalName" placeholder="Enter Business Legal Name" value={businessLegalName} onChange={(e) => setBusinessLegalName(e.target.value)} required />
        </div>

        <div className="input-group">
          <label htmlFor="phone">Phone</label>
          <input type="text" id="phone" placeholder="Enter Phone Number with Area Code" value={phone} onChange={(e) => setPhone(e.target.value)} required />
        </div>

        <div className="input-group">
          <label htmlFor="address">Address</label>
          <input type="text" id="address" placeholder="Enter Business Address" value={address} onChange={(e) => setAddress(e.target.value)} required />
        </div>

        <div className="input-group">
          <label htmlFor="zipCode">Zip Code</label>
          <input type="text" id="zipCode" placeholder="Enter Zip Code" value={zipCode} onChange={(e) => setZipCode(e.target.value)} required />
        </div>

        <div className="input-group">
          <label htmlFor="howDidYouHearAboutUs">How did you hear about us?</label>
          <select id="howDidYouHearAboutUs" value={howDidYouHearAboutUs} onChange={(e) => setHowDidYouHearAboutUs(e.target.value)} required>
            <option value="">Select</option>
            <option value="JCK">JCK</option>
            <option value="Word Of Mouth">Word Of Mouth</option>
            <option value="Ads">Ads</option>
          </select>
        </div>

        <button type="submit">Complete Profile</button>
      </form>
    </div>
  );
}

export default ProfileCompletion;
