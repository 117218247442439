import React, { useState, useEffect } from 'react';
import { Container, Table, Button, Alert, Modal, Form } from 'react-bootstrap';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc, updateDoc, addDoc, collection } from 'firebase/firestore';
import { format, addDays } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import './Cart.css';

function Cart() {
    const [cartItems, setCartItems] = useState([]);
    const [error, setError] = useState('');
    const [confirmOrder, setConfirmOrder] = useState(false);
    const [address, setAddress] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [formError, setFormError] = useState('');
    const navigate = useNavigate();

    const auth = getAuth();
    const db = getFirestore();

    const handleOrderConfirmation = () => {
        if (!address || !zipCode) {
            setFormError('Please fill in both address and zip code.');
            return;
        }
        setFormError('');
        setConfirmOrder(true);
    };

    const confirmAndPlaceOrder = async () => {
        setConfirmOrder(false);
        handleOrder();
    };

    useEffect(() => {
        const fetchCartItems = async () => {
            if (auth.currentUser) {
                const userCartRef = doc(db, "userCarts", auth.currentUser.uid);
                const docSnap = await getDoc(userCartRef);
                if (docSnap.exists()) {
                    setCartItems(docSnap.data().cartItems || []);
                } else {
                    setError("No cart items found.");
                }
            } else {
                setError("User not logged in.");
            }
        };

        const fetchUserAddress = async () => {
            if (auth.currentUser) {
                const userRef = doc(db, "users", auth.currentUser.uid);
                const docSnap = await getDoc(userRef);
                if (docSnap.exists()) {
                    const userData = docSnap.data();
                    setAddress(userData.address || '');
                    setZipCode(userData.zipCode || '');
                } else {
                    setError("User address not found.");
                }
            }
        };

        fetchCartItems();
        fetchUserAddress();
    }, [auth, db]);

    const removeFromCart = async (indexToRemove) => {
        const updatedItems = cartItems.filter((_, index) => index !== indexToRemove);
        setCartItems(updatedItems);

        if (auth.currentUser) {
            const userCartRef = doc(db, "userCarts", auth.currentUser.uid);
            await updateDoc(userCartRef, { cartItems: updatedItems });
        }
    };

    const subTotal = cartItems.reduce((acc, item) => acc + item.total, 0);

    const handleOrder = async () => {
        if (auth.currentUser) {
            const transactionRef = collection(db, "userTransactions");
            const transactionData = {
                userId: auth.currentUser.uid,
                items: cartItems.map(item => ({
                    shape: item.diamond_shape,
                    stockId: item.stockId,
                    quantityInCarats: item.quantity_ordered,
                    estimatedDelivery: format(addDays(new Date(), 1), 'PPP'),
                    unitPrice: item.unit_price,
                    itemTotal: item.total,
                })),
                date: format(new Date(), 'PPPppp'),
                transactionId: `TX-${Date.now()}`,
                complete: false,
                trackingId: "",
                totalAmount: subTotal,
                address,
                zipCode,
            };

            try {
                await addDoc(transactionRef, transactionData);
                const userRef = doc(db, "users", auth.currentUser.uid);
                const userSnap = await getDoc(userRef);
                let points = subTotal;
                if (userSnap.exists() && userSnap.data().points) {
                    points += userSnap.data().points;
                }
                await updateDoc(userRef, { points });
                await updateDoc(doc(db, "userCarts", auth.currentUser.uid), { cartItems: [] });
                setCartItems([]);
                navigate('/order-complete', { state: { transactionId: transactionData.transactionId } });
            } catch (error) {
                setError("Error placing order: " + error.message);
            }
        }
    };

    return (
        <Container>
            <h2 className="my-4 cart-title">Your Cart</h2>
            <p>Thank you for shopping with us! After you place your order, you will receive an email confirmation within an hour, detailing your order summary along with the estimated shipping date. Please review this information carefully. If you are satisfied with the details, you can approve your order by following the instructions provided in the email. Upon approval, you will receive an invoice with all relevant payment details to complete your purchase. If you have any questions or need assistance, our customer support team is here to help.</p>
            {error && <Alert variant="danger">{error}</Alert>}
            {cartItems.length > 0 ? (
                <>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Item</th>
                                <th>Size (mm)</th>
                                <th>Quantity (ct)</th>
                                <th>Total ($)</th>
                                <th>Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cartItems.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.diamond_shape}</td>
                                    <td>{item.length} x {item.breadth}</td>
                                    <td>{item.quantity_ordered.toFixed(2)}</td>
                                    <td>{item.total.toFixed(2)}</td>
                                    <td><Button variant="danger" onClick={() => removeFromCart(index)}>Remove</Button></td>
                                </tr>
                            ))}
                            <tr className="subtotal-row">
                                <td colSpan="4">Subtotal</td>
                                <td colSpan="3">${subTotal.toFixed(2)}</td>
                            </tr>
                        </tbody>
                    </Table>
                    <Form>
                        <Form.Group className="mb-3" controlId="formAddress">
                            <Form.Label>Address</Form.Label>
                            <Form.Control type="text" placeholder="Enter address" value={address} onChange={e => setAddress(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formZipCode">
                            <Form.Label>Zip Code</Form.Label>
                            <Form.Control type="text" placeholder="Enter zip code" value={zipCode} onChange={e => setZipCode(e.target.value)} />
                        </Form.Group>
                        {formError && <Alert variant="danger">{formError}</Alert>}
                        <Button variant="success" onClick={handleOrderConfirmation}>Place Order</Button>
                    </Form>
                </>
            ) : <Alert variant="info">Your cart is empty.</Alert>}

            <Modal show={confirmOrder} onHide={() => setConfirmOrder(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Your Order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Please confirm your order details before proceeding:</p>
                    <ul>
                        {cartItems.map((item, index) => (
                            <li key={index}>
                                {item.diamond_shape} - {item.quantity_ordered.toFixed(2)} ct - ${item.total.toFixed(2)}
                            </li>
                        ))}
                    </ul>
                    <p><strong>Subtotal:</strong> ${subTotal.toFixed(2)}</p>
                    <p><strong>Shipping Address:</strong> {address}</p>
                    <p><strong>Zip Code:</strong> {zipCode}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setConfirmOrder(false)}>Cancel</Button>
                    <Button variant="primary" onClick={confirmAndPlaceOrder}>Confirm Order</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default Cart;
