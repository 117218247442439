import React, { useState, useEffect } from 'react';
import { Container, ListGroup, Alert, Row, Col, Nav, Tab, Button, Table, Form, InputGroup } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs, doc, getDoc, updateDoc } from 'firebase/firestore';
import './Account.css';
import CustomCard from '../elements/CustomCard';

function Account() {
    const [key, setKey] = useState('transactions'); // Default active tab key
    const [transactions, setTransactions] = useState([]);
    const [inquiries, setInquiries] = useState([]);
    const [profileCompleted, setProfileCompleted] = useState(false);
    const [points, setPoints] = useState(0);
    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        zipCode: '',
        businessName:'',
      });
    const auth = getAuth();
    const db = getFirestore();
    const navigate = useNavigate();

    // Fetch points, profile status, transactions, and inquiries
    useEffect(() => {
        if (auth.currentUser) {
            const userRef = doc(db, "users", auth.currentUser.uid);
            getDoc(userRef).then(docSnap => {
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setProfileCompleted(data.profileCompleted);
                    setPoints(data.points || 0);
                    setUserData({
                        firstName: data.firstName || '',
                        lastName: data.lastName || '',
                        email: data.email || '',
                        phone: data.phone || '',
                        address: data.address || '',
                        zipCode: data.zipCode || '',
                        businessName: data.businessLegalName || '',
                    });
                }
            });

            // Fetch transactions
            const transQuery = query(collection(db, "userTransactions"), where("userId", "==", auth.currentUser.uid));
            getDocs(transQuery).then(querySnapshot => {
                const fetchedTransactions = querySnapshot.docs.map(doc => doc.data());
                console.log(fetchedTransactions);
                setTransactions(fetchedTransactions);
            });

            // Fetch inquiries
            const inqQuery = query(collection(db, "userQueries"), where("uid", "==", auth.currentUser.uid), where("inquiry", "==", true));
            getDocs(inqQuery).then(querySnapshot => {
                const fetchedInquiries = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
                console.log(fetchedInquiries);
                setInquiries(fetchedInquiries);
            });
        }
    }, [auth, db]);

    const handleFileDownload = (fileUrl) => {
        window.open(fileUrl, '_blank');
    };

    if (!profileCompleted) {
        return (
            <Container>
                <Alert variant="warning">
                    You must complete your profile before accessing your account.
                    <br />
                    <Link to="/profile-completion">Click here to complete your profile</Link>.
                </Alert>
            </Container>
        );
    }

    const updateUserData = async () => {
        const userRef = doc(db, "users", auth.currentUser.uid);
        try {
            await updateDoc(userRef, userData);
            alert('User data updated successfully.');
        } catch (error) {
            console.error("Error updating user data: ", error);
            alert('Error updating user data.');
        }
    };

    const safeMap = (array, callback) => Array.isArray(array) ? array.map(callback) : [];

    const transactionPane = transactions.length > 0 ? transactions.map((transaction, index) => (
        <CustomCard key={index} className="info-card mb-3" 
          header={<div className="info-card-header">Order ID: {transaction.transactionId}</div>}
          footer={
            <div className="info-card-footer">
              <a className="info-link" href={`/invoices/${transaction.transactionId}`}>View Invoice</a> | 
              <a className="info-link" href={`/details/${transaction.transactionId}`}>View Details</a>  | 
              <Link className="info-link" to="/help">Ask for Help</Link>
            </div>
          }
        >
          <ListGroup variant="flush">
            <ListGroup.Item className="info-item">
              <div className="info-detail">
                <strong>Total Quantity in Carats:</strong> {transaction.items.reduce((total, item) => total + item.quantityInCarats, 0)}
              </div>
              <div className="info-detail">
                <strong>Total Amount:</strong> ${transaction.totalAmount}
              </div>
              <div className="info-detail">
                <strong>Date:</strong> {transaction.date}
              </div>
              <div className="info-detail">
                <strong>Tracking ID:</strong> {transaction.trackingId || 'Pending'}
              </div>
              <div className="info-status">
                <strong>Status:</strong> {transaction.complete ? 'Complete' : 'Incomplete'}
              </div>
            </ListGroup.Item>
          </ListGroup>
        </CustomCard>
      )) : <Alert variant="info">You have no transactions.</Alert>;
  
      const inquiryPane = inquiries.length > 0 ? inquiries.map((inquiry, index) => (
        <CustomCard
          key={index}
          className="info-card mb-3"
          header={
            <div className="info-card-header">
              Inquiry ID: {inquiry.id} - {inquiry.inquiry ? "Inquiry" : "Incomplete"}
            </div>
          }
          footer={
            inquiry.fileUrl && (
              <div className="info-card-footer">
                Custom File Download: <Button className="info-button" onClick={() => handleFileDownload(inquiry.fileUrl)}>Download File</Button>
              </div>
            )
          }
        >
          <ListGroup variant="flush">
            {inquiry.details ? Object.entries(inquiry.details).map(([shapeName, detailArray], shapeIndex) => (
              <ListGroup.Item key={shapeIndex} className="info-item">
                <div className="info-detail">
                  <strong>Shape:</strong> {shapeName}
                </div>
                {Array.isArray(detailArray) && (
                  <Table striped bordered hover size="sm" className="additional-info-table">
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Size</th>
                        <th>Length</th>
                        <th>Breadth</th>
                        <th>Color</th>
                        <th>Clarity</th>
                        <th>Measurements</th>
                      </tr>
                    </thead>
                    <tbody>
                      {detailArray.map((detail, detailIndex) => (
                        <tr key={detailIndex}>
                          <td>{detail.type}</td>
                          <td>{detail.size}</td>
                          <td>{detail.length}</td>
                          <td>{detail.breadth}</td>
                          <td>{detail.color}</td>
                          <td>{detail.clarity}</td>
                          <td>{detail.measurements || 'N/A'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </ListGroup.Item>
            )) : (
              inquiry.fileUrl && (
                <ListGroup.Item className="info-item">
                  <div className="info-detail">
                    This inquiry is based on a file submission.
                  </div>
                </ListGroup.Item>
              )
            )}
            <div className="info-detail">
                {inquiry.additionalComments}
            </div>
          </ListGroup>
        </CustomCard>
      )) : <Alert variant="info">You have no open inquiries.</Alert>;
      
      
    
      
    return (
        <Container className="account-container my-5">
          <Row>
            <Col lg={3} className="account-nav-col">
              <Nav variant="pills" className="account-nav flex-column" activeKey={key} onSelect={(k) => setKey(k)}>
                <Nav.Item>
                  <Nav.Link eventKey="transactions">Transactions</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="inquiries">Open Inquiries</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="user-data">My Profile</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col lg={6} className="account-main-col">
              <Tab.Content>
                <Tab.Pane eventKey="transactions" active={key === 'transactions'}>
                  {transactionPane}
                </Tab.Pane>
                <Tab.Pane eventKey="inquiries" active={key === 'inquiries'}>
                  {inquiryPane}
                </Tab.Pane>
                <Tab.Pane eventKey="user-data" active={key === 'user-data'}>
                    <h2>{userData.businessName}</h2>
                    <Form>
                        {Object.entries(userData).map(([key, value]) => (
                        <InputGroup className="input-group" key={key}>
                            <label>{key.charAt(0).toUpperCase() + key.slice(1)}</label>
                            <input 
                            type="text" 
                            value={value} 
                            onChange={(e) => setUserData({...userData, [key]: e.target.value})}
                            />
                        </InputGroup>
                        ))}
                        <Button onClick={updateUserData}>Save</Button>
                    </Form>
                </Tab.Pane>
              </Tab.Content>
            </Col>
            <Col lg={3} className="account-points-col">
              <div className="points-section">
                <h5 className="points-title">Points</h5>
                <p className="points-value">{points.toFixed(2)}</p>
                <p className="points-info">Points give you exclusive discounts on purchases. Click here to learn more.</p>
              </div>
            </Col>
          </Row>
        </Container>
      );
}

export default Account;
