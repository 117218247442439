import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Modal, Form, Card, Tabs, Tab, Table, Badge } from 'react-bootstrap';
import { getFirestore, collection, query, where, getDocs, updateDoc, doc, deleteDoc, getDoc } from 'firebase/firestore';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import Papa from 'papaparse';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const AdminConsole = () => {
    const [key, setKey] = useState('incomplete');
    const [transactions, setTransactions] = useState([]);
    const [inquiries, setInquiries] = useState([]);
    const [customerQuestions, setCustomerQuestions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [ShowModalForInvoiceSubmission, setShowModalForInvoiceSubmission] = useState(false);
    const [currentTransaction, setCurrentTransaction] = useState({});
    const [file, setFile] = useState(null);
    const [trackingNumber, setTrackingNumber] = useState('');
    const db = getFirestore();

    // For chart data
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [{
            label: 'Total Amount Per Day',
            data: [],
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        }],
    });

    const exportToCSV = () => {
        const nonDownloadableInquiries = inquiries.filter(inquiry => !inquiry.fileUrl);
        const dataToExport = nonDownloadableInquiries.map((inquiry, index) => {
            let baseObject = {
                'Sr. No': index + 1,
            };
        
            // Assuming each inquiry will have at least one query, and you only need to map the first one for simplicity.
            // Adjust this logic if you need to handle multiple queries differently.
            const firstQuery = inquiry.queries[0];
        
            if (firstQuery) {
                const shape = firstQuery.shape === 'Other' ? firstQuery.customShape : firstQuery.shape;
                const measurements = `${firstQuery.sizeLength || 'N/A'}x${firstQuery.sizeWidth || 'N/A'} mm`;
        
                baseObject['Shape'] = shape;
                baseObject['Measurements'] = measurements;
        
                // Add additional info fields from the first query
                Object.entries(firstQuery.additionalInfo || {}).forEach(([key, value]) => {
                    baseObject[key] = value;
                });
            }
        
            return baseObject;
        });
    
        const csv = Papa.unparse(dataToExport);
        const csvBlob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(csvBlob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Inquiries.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const fetchUserDetails = async (userId) => {
        const userRef = doc(db, "users", userId);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
            return userSnap.data(); // Return user details
        } else {
            return null; // Handle the case where the user doesn't exist
        }
    };

    useEffect(() => {
        fetchTransactions();
        fetchUserQueries();
    }, []);

    // Enhanced fetchTransactions function
    const fetchTransactions = async () => {
        const transactionsSnapshot = await getDocs(collection(db, "userTransactions"));
        const transactionsPromises = transactionsSnapshot.docs.map(async (doc) => {
            const transaction = { ...doc.data(), id: doc.id };
            // Fetch user details
            const userDetails = await fetchUserDetails(transaction.userId);
            // Combine transaction data with user details
            return { ...transaction, userEmail: userDetails?.email, userPhone: userDetails?.phone };
        });

        // Wait for all transactions to be enriched with user details
        const enrichedTransactions = await Promise.all(transactionsPromises);
        setTransactions(enrichedTransactions);
        // Other logic to prepare chart data etc.
    };

    const fetchUserQueries = async () => {
        const inquiriesSnapshot = await getDocs(collection(db, "userQueries"));
        let fetchedInquiries = inquiriesSnapshot.docs.map(doc => {
            const data = doc.data();
            // Transform queries to handle additional info
            const queries = data.queries ? data.queries.map(query => ({
                ...query,
                additionalInfo: query.additionalInfo || {},
            })) : [];
            return { ...data, queries, id: doc.id };
        });
        setInquiries(fetchedInquiries);

        // Fetch customer questions
        const questionsSnapshot = await getDocs(query(collection(db, "userQueries"), where("inquiry", "==", false)));
        let fetchedQuestions = questionsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setCustomerQuestions(fetchedQuestions);
    };

    const handleUpdateTrackingNumber = async () => {
        if (!currentTransaction.id) return;
        await updateDoc(doc(db, "userTransactions", currentTransaction.id), { trackingNumber });
        setShowModal(false);
        setTrackingNumber('');
        fetchTransactions();
    };

    const handleUpdateInvoice = async () => {
        if (!currentTransaction.id || !file) return; // Ensure transaction document ID and file are available
    
        const storage = getStorage();
        const db = getFirestore();
    
        try {
            // Fetch the current transaction document to get the transactionId field
            const transactionDocRef = doc(db, "userTransactions", currentTransaction.id);
            const transactionDocSnap = await getDoc(transactionDocRef);
    
            if (!transactionDocSnap.exists()) {
                console.error("No matching transaction found");
                return;
            }
    
            // Use transactionId from the document for the file name
            const transactionId = transactionDocSnap.data().transactionId;
            const storageRef = ref(storage, `user_inquiries/invoice_${transactionId}`);
    
            // Upload the file to Firebase Storage
            const snapshot = await uploadBytes(storageRef, file);
            // Get the download URL
            const downloadURL = await getDownloadURL(snapshot.ref);
    
            // Update the transaction document with the invoice download URL
            await updateDoc(transactionDocRef, {
                invoice: downloadURL
            });
            console.log("Invoice updated successfully");
        } catch (error) {
            console.error("Error updating invoice:", error);
        }
    };
    

    const openModalForTrackingUpdate = (transaction) => {
        setCurrentTransaction(transaction);
        setShowModal(true);
    };

    const openModalForInvoiceSubmission = (transaction) => {
        setCurrentTransaction(transaction);
        setShowModalForInvoiceSubmission(true);
    };


    const handleMarkAsComplete = async () => {
        if (!currentTransaction.id) return;
        await updateDoc(doc(db, "userTransactions", currentTransaction.id), { complete: true });
        setShowModal(false);
        fetchTransactions();
    };

    const prepareChartData = (dailyAmounts) => {
        setChartData({
            labels: Object.keys(dailyAmounts),
            datasets: [{
                label: 'Total Amount Per Day',
                data: Object.values(dailyAmounts),
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            }],
        });
    };

        // Function to mark an inquiry as complete
    const handleCompleteInquiry = async (inquiryId) => {
        await updateDoc(doc(db, "userQueries", inquiryId), { complete: true });
        fetchUserQueries(); // Refetch to update the UI
    };

    // Function to delete an inquiry
    const handleDeleteInquiry = async (inquiryId) => {
        await deleteDoc(doc(db, "userQueries", inquiryId));
        fetchUserQueries(); // Refetch to update the UI
    };

    const handleFileChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setFile(event.target.files[0]);
        }
    };


    const InquiryCard = ({ inquiry, handleCompleteInquiry, handleDeleteInquiry }) => {
        // Function to download the inquiry file
        const handleDownload = (url) => {
            window.open(url, '_blank');
        };
    
        return (
            <Card className="mb-3">
                <Card.Body>
                    <Card.Title>Email: {inquiry.email}</Card.Title>
                    {inquiry.fileUrl ? (
                        <>
                            <Button variant="info" onClick={() => handleDownload(inquiry.fileUrl)}>Download Sheet</Button>
                        </>
                    ) : (
                        inquiry.queries && inquiry.queries.map((query, idx) => (
                            <div key={idx} className="mb-2">
                                <Badge bg="secondary" className="me-2">
                                    {query.shape || query.customShape || 'N/A'}: {query.sizeLength || 'N/A'}x{query.sizeWidth || 'N/A'}
                                </Badge>
                                {query.additionalInfo && Object.entries(query.additionalInfo).map(([key, value]) => (
                                    <Badge bg="info" key={key} className="me-2">{`${key}: ${value}`}</Badge>
                                ))}
                            </div>
                        ))
                    )}
                    {!inquiry.complete && !inquiry.fileUrl && (
                        <Button variant="success" size="sm" onClick={() => handleCompleteInquiry(inquiry.id)}>Mark as Complete</Button>
                    )}
                    <Button variant="danger" size="sm" onClick={() => handleDeleteInquiry(inquiry.id)} className="ms-2">Delete</Button>
                    {inquiry.complete && <Badge bg="success" className="ms-2">Resolved</Badge>}
                </Card.Body>
            </Card>
        );
    };
    

    const TransactionCard = ({ transaction, handleUpdate, handleComplete, readOnly = false }) => (
        <Card className="mb-3">
            <Card.Header>
                Transaction ID: {transaction.transactionId}
                {!readOnly && (
                    <div className="float-end">
                        <Button size="sm" variant="success" onClick={() => openModalForTrackingUpdate(transaction)}>Update Tracking</Button>{' '}
                        <Button size="sm" variant="success" onClick={() => openModalForInvoiceSubmission(transaction)}>Add Invoice</Button>{' '}
                        <Button size="sm" variant="primary" onClick={() => handleComplete(transaction)}>Mark Complete</Button>
                    </div>
                )}
            </Card.Header>
            <Card.Body>
                <p>Email: {transaction.userEmail}</p>
                <p>Phone: {transaction.userPhone}</p>
                <Table striped bordered>
                    <thead>
                        <tr>
                            <th>Stock ID</th>
                            <th>Quantity</th>
                            <th>Unit Price</th>
                            <th>Total</th>
                            <th>Estimated Delivery</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transaction.items.map((item, idx) => (
                            <tr key={idx}>
                                <td>{item.stockId}</td>
                                <td>{item.quantityInCarats}</td>
                                <td>${item.unitPrice}</td>
                                <td>${item.itemTotal}</td>
                                <td>{item.estimatedDelivery}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                </Card.Body>
                <Card.Footer>
                Total Amount: ${transaction.totalAmount.toFixed(2)}
                {transaction.trackingNumber && <div>Tracking Number: {transaction.trackingNumber}</div>}
                </Card.Footer>
        </Card>
    );
    
    const QuestionCard = ({ question }) => (
        <Card className="mb-3 shadow-sm">
            <Card.Body>
                <Card.Title>Email: {question.email}</Card.Title>
                <Card.Text>{question.question}</Card.Text>
            </Card.Body>
        </Card>
    );

    return (
        <Container fluid>
            <Row>
                <Col md={8}>
                    <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
                        <Tab eventKey="incomplete" title="Incomplete">
                            {transactions.filter(t => !t.complete).map(transaction => (
                                // Use the existing Card component for transactions
                                <TransactionCard transaction={transaction} handleUpdate={handleUpdateTrackingNumber} handleComplete={handleMarkAsComplete} />
                            ))}
                        </Tab>
                        <Tab eventKey="complete" title="Complete">
                            {transactions.filter(t => t.complete).map(transaction => (
                                <TransactionCard transaction={transaction} readOnly />
                            ))}
                        </Tab>
                        <Tab eventKey="inquiries" title="Inquiries">
                            <Button onClick={exportToCSV} variant="primary" className="mb-3">Export to Excel</Button>
                            {inquiries.map((inquiry, index) => (
                                <InquiryCard key={index} inquiry={inquiry} handleCompleteInquiry={handleCompleteInquiry} handleDeleteInquiry={handleDeleteInquiry} />
                            ))}
                        </Tab>
                        <Tab eventKey="customerQuestions" title="Customer Questions">
                            {customerQuestions.map((question, index) => (
                                <QuestionCard key={index} question={question} />
                            ))}
                        </Tab>
                    </Tabs>
                </Col>
                <Col md={4}>
                    <h2>Monitoring</h2>
                    <Bar data={chartData} options={{ responsive: true, scales: { y: { beginAtZero: true } } }} />
                </Col>
            </Row>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Tracking Number</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Tracking Number</Form.Label>
                            <Form.Control type="text" value={trackingNumber} onChange={(e) => setTrackingNumber(e.target.value)} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                    <Button variant="primary" onClick={handleUpdateTrackingNumber}>Update</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={ShowModalForInvoiceSubmission} onHide={() => setShowModalForInvoiceSubmission(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Upload Invoice PDF</Form.Label>
                            <Form.Control type="file" accept=".xlsx,.xls,.csv,.numbers,.pdf" onChange={handleFileChange} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalForInvoiceSubmission(false)}>Close</Button>
                    <Button variant="primary" onClick={handleUpdateInvoice}>Add</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};
