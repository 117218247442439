import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

function Home() {
  const [userName, setUserName] = useState('');
  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const fetchUserName = async () => {
      if (auth.currentUser) {
        const userRef = doc(db, "users", auth.currentUser.uid);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          setUserName(`${docSnap.data().firstName} ${docSnap.data().lastName}`);
        }
      }
    };

    fetchUserName();
  }, []);

  return (
    // Made the background container lighter and removed the border
    <Container className="mt-5 p-5" style={{ minHeight: '100vh', backgroundImage: 'url("/diamond-bg.jpg")', backgroundSize: 'cover', color: '#000', backgroundColor: 'rgba(255, 255, 255, 0.8)', backdropFilter: 'blur(5px)' }}>
      <Row className="justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
        <Col md={8} className="text-center">
          {/* Made the welcome text dark maroon and paragraph text black */}
          <h1 style={{ color: '#000000' }}>Welcome to Nirvana, {userName}!</h1>
          <p style={{ color: '#000' }}>
          Welcome to Nirvana Jewels, where precision meets perfection. Our exquisite collection of HPHT diamonds is meticulously crafted to meet the highest standards of excellence. As specialists in fancy calibrated loose stones, we guarantee unparalleled accuracy in every measurement, ensuring that each diamond you purchase from us is flawless and unmatched. Explore our diverse stock list, create an account to view exclusive prices, and experience the art of fine jewelry with Nirvana Jewels. Join us in celebrating the brilliance of perfection and elevate your collection with diamonds that truly shine. Your journey to extraordinary elegance begins here.
          </p>
          <Button variant="primary" href="/purchase" style={{ backgroundColor: '#000000', borderColor: '#000000', color: '#fff' }}>Browse Diamonds</Button>
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
