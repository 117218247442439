import React, { useState } from 'react';
import { Form, Button, Container, Alert } from 'react-bootstrap';
import { auth } from '../firebase-config'; // Ensure this path is correct
import { createUserWithEmailAndPassword, updateProfile, sendEmailVerification } from 'firebase/auth';
import { db } from '../firebase-config';
import { collection, addDoc, setDoc, doc } from 'firebase/firestore';
import './FormStyles.css';

function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [businessId, setBusinessId] = useState('');
  const [businessLegalName, setBusinessLegalName] = useState('');
  const [howDidYouHearAboutUs, setHowDidYouHearAboutUs] = useState('');
  const [error, setError] = useState('');
  const [signedUp, setSignedUp] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');

    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        await updateProfile(user, { displayName: `${firstName} ${lastName}` });
        await sendEmailVerification(user);
        await setDoc(doc(db, "users", user.uid), { 
            uid: user.uid, 
            firstName, 
            lastName,
            email
        }, { merge: true });
        setSignedUp(true);
    } catch (error) {
      setError(error.message);
    }
  };

  if (signedUp) {
    return (
      <Container className="mt-5 p-5 bg-light rounded shadow" style={{ maxWidth: '600px' }}>
        <Alert variant="success">
          Sign-up successful! A verification email has been sent. Please verify your email before logging in.
        </Alert>
      </Container>
    );
  }
  return (
    <div className="form-container">
      <h2 className="text-center mb-4">Sign Up</h2>
      <p className="mb-4">Sign up for an account to see our prices, get exclusive offers, and place orders. You're one step away from the best deals on the highest quality goods available!</p>
      {error && <Alert variant="danger">{error}</Alert>}
      <form  onSubmit={handleSubmit} className="d-grid gap-3">
        <div className="input-group">
            <label>First Name</label>
            <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
        </div>

        <div className="input-group">
            <label>Last Name</label>
            <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
        </div>

        <div className="input-group">
            <label>Business Email</label>
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </div>

        <div className="input-group">
            <label>Password</label>
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        </div>

        <Button variant="primary" type="submit" className="mt-3" style={{ backgroundColor: '#000000', borderColor: '#000000' }}>
          Sign Up
        </Button>
      </form>
    </div>
  );
}

export default SignUp;
