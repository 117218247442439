import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Table, Alert } from 'react-bootstrap';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, getDocs } from 'firebase/firestore';
import './Details.css'; // Custom CSS for styling
import { auth, db } from '../firebase-config'; // Import auth and db

function Details() {
    const { transactionId } = useParams();
    const [transaction, setTransaction] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [authInitialized, setAuthInitialized] = useState(false);

    useEffect(() => {
        const checkAuthState = () => {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    setAuthInitialized(true);
                } else {
                    setError("User not authenticated");
                    setLoading(false);
                }
            });
        };

        checkAuthState();
    }, []);

    useEffect(() => {
        const fetchTransaction = async () => {
            if (!authInitialized) return;

            try {
                const transactionsRef = collection(db, "userTransactions");
                const q = query(transactionsRef, where("transactionId", "==", transactionId));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const doc = querySnapshot.docs[0];
                    setTransaction(doc.data());
                } else {
                    setError("Transaction not found.");
                }
            } catch (err) {
                setError("Failed to fetch transaction: " + err.message);
            }
            setLoading(false);
        };

        fetchTransaction();
    }, [transactionId, authInitialized]);

    if (loading) {
        return <Container><Alert variant="info">Loading...</Alert></Container>;
    }

    if (error) {
        return <Container><Alert variant="danger">{error}</Alert></Container>;
    }

    return (
        <Container className="my-4">
            <h2>Transaction Details</h2>
            {transaction ? (
                <Table striped bordered hover className="custom-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Stock ID</th>
                            <th>Shape</th>
                            <th>Quantity (ct)</th>
                            <th>Unit Price ($)</th>
                            <th>Item Total ($)</th>
                            <th>Estimated Delivery</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transaction.items.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.stockId}</td>
                                <td>{item.shape || 'N/A'}</td>
                                <td>{item.quantityInCarats.toFixed(2)}</td>
                                <td>{item.unitPrice.toFixed(2)}</td>
                                <td>{item.itemTotal.toFixed(2)}</td>
                                <td>{item.estimatedDelivery}</td>
                            </tr>
                        ))}
                        <tr className="custom-subtotal-row">
                            <td colSpan="5">Total Amount</td>
                            <td colSpan="2">${transaction.totalAmount.toFixed(2)}</td>
                        </tr>
                    </tbody>
                </Table>
            ) : (
                <Alert variant="info">No transaction details available.</Alert>
            )}
        </Container>
    );
}

export default Details;
