import React, { useState, useEffect } from 'react';
import { Container, Card, Table, Button, Form, Alert, OverlayTrigger, Tooltip, Tab, Nav, Row, Col, Modal } from 'react-bootstrap';
// Firebase imports
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc, addDoc, collection, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

import './Purchase.css'; // Reuse styles from Purchase.css
import { fetchShapeData } from '../utils/DataService'; // Adjust the path as necessary

function Inquiry() {
    const [selectedShapes, setSelectedShapes] = useState({});
    const [inquiries, setInquiries] = useState({});
    const [diamondShapes, setDiamondShapes] = useState([]);
    const [email, setEmail] = useState('');
    const [additionalComments, setAdditionalComments] = useState('');
    const [file, setFile] = useState(null);
    const auth = getAuth();
    const db = getFirestore();
    const storage = getStorage(); // Initialize storage
    const [customShapes, setCustomShapes] = useState([]);
    const [showCustomShapeModal, setShowCustomShapeModal] = useState(false);
    const [newCustomShapeName, setNewCustomShapeName] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const docRef = doc(db, 'users', user.uid);
                getDoc(docRef).then((docSnap) => {
                    if (docSnap.exists()) {
                        setEmail(docSnap.data().email);
                    }
                });
            }
        });
    }, [auth, db]);

    useEffect(() => {
        const loadData = async () => {
            const shapes = await fetchShapeData();
            const shapeDataWithImages = await Promise.all(shapes.map(async (shape) => {
                const shapeNameKey = shape.name.replace(/\s/g, "").toLowerCase(); // Format the shape name to match your storage keys
                const imageUrl = await fetchShapeImageUrl(shapeNameKey);
                return { name: shape.name, image: imageUrl };
            }));
        
            setDiamondShapes(shapeDataWithImages);
        };
        
        loadData();
    }, []);

    const fetchShapeImageUrl = async (shapeNameKey) => {
        const storageRef = ref(storage, `diamond-icons/${shapeNameKey}-image.png`);
        try {
            return await getDownloadURL(storageRef);
        } catch (error) {
            console.error("Error fetching image URL for shape", shapeNameKey, error);
            return ''; // Return a default or error image path as necessary
        }
    };

    const resetInquiryStates = () => {
        setSelectedShapes({});
        setInquiries({});
        setFile(null);
        // Reset any other states you wish to reset here
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const toggleShapeSelection = (shapeName) => {
        setSelectedShapes(prev => ({
            ...prev,
            [shapeName]: !prev[shapeName]
        }));

        // Initialize or remove the inquiry entry for the shape
        setInquiries(prev => {
            const newInquiries = { ...prev };
            if (newInquiries[shapeName]) {
                delete newInquiries[shapeName];
            } else {
                newInquiries[shapeName] = [{ type: '', size: '', length: '', breadth: '', color: '', clarity: '' }];
            }
            return newInquiries;
        });
    };

    const handleInquiryChange = (shapeName, rowIndex, fieldName, value) => {
        setInquiries(prev => ({
            ...prev,
            [shapeName]: prev[shapeName].map((row, index) => {
                if (index === rowIndex) {
                    return { ...row, [fieldName]: value };
                }
                return row;
            })
        }));
    };

    const addCustomShape = () => {
        if (newCustomShapeName.trim() === '') {
            alert('Please enter a shape name.');
            return;
        }
    
        const newShape = {
            name: newCustomShapeName.trim(),
            image: '', // Use an empty string or a placeholder for custom shapes
            custom: true // Optionally mark the shape as custom, if needed for other logic
        };
    
        // Update the diamondShapes state to include the new custom shape
        setDiamondShapes(prevShapes => [...prevShapes, newShape]);
    
        // Initialize inquiry entry for the new custom shape
        setInquiries(prevInquiries => ({
            ...prevInquiries,
            [newCustomShapeName.trim()]: [{ type: '', size: '', length: '', breadth: '', color: '', clarity: '' }]
        }));
    
        // Reset modal state and close it
        setNewCustomShapeName('');
        setShowCustomShapeModal(false);
    };

    const addInquiryRow = (shapeName) => {
        setInquiries(prev => ({
            ...prev,
            [shapeName]: [...(prev[shapeName] ?? []), { type: '', size: '', length: '', breadth: '', color: '', clarity: '' }]
        }));
    };

    const removeInquiryRow = (shapeName, rowIndex) => {
        setInquiries(prev => ({
            ...prev,
            [shapeName]: prev[shapeName].filter((_, index) => index !== rowIndex)
        }));
    };

    const submitInquiry = async () => {
        if (!auth.currentUser) {
            console.error("User not logged in");
            return;
        }

        if (!(!file)) {
            handleFileUpload();
        } else {

            try {
                const userQueriesRef = doc(db, "userQueries", auth.currentUser.uid);
                await setDoc(userQueriesRef, { inquiry: true, details: inquiries, additionalComments: additionalComments, ...(auth.currentUser && { uid: auth.currentUser.uid }) }, { merge: true });
                console.log("Inquiry submitted to Firestore");

                setShowSuccessMessage(true);
                setTimeout(() => setShowSuccessMessage(false), 5000); 
                resetInquiryStates();

            } catch (error) {
                console.error("Error submitting inquiry: ", error);
            }
        }
    };

    const handleFileUpload = async () => {
        if (!file) {
            alert('Please select a file before submitting.');
            return;
        }
    
        const fileRef = ref(storage, `inquiries/${new Date().getTime()}_${file.name}`);
        await uploadBytes(fileRef, file).then(async (snapshot) => {
            const fileUrl = await getDownloadURL(snapshot.ref);
            const docData = {
                email,
                inquiry: true,
                fileUrl,
                ...(auth.currentUser && { uid: auth.currentUser.uid })
            };
            await addDoc(collection(db, 'userQueries'), docData);
            alert('File successfully uploaded.');
        });
    };
    

    return (
        <Container className="mt-5">
            <style type="text/css">
                  {`
                      .img-thumbnail {
                          width: 75px; /* Smaller width */
                          height: 75px; /* Smaller height to maintain aspect ratio */
                          background-size: contain; /* Contain the background image within the button */
                          background-repeat: no-repeat;
                          background-position: center;
                          margin: 5px; /* Add some space around the buttons */
                      }

                      .img-thumbnail:hover {
                          animation: wiggle 0.5s ease-in-out;
                      }
                      
                      @keyframes wiggle {
                          0% { transform: rotate(-5deg); }
                          25% { transform: rotate(5deg); }
                          50% { transform: rotate(-5deg); }
                          75% { transform: rotate(5deg); }
                          100% { transform: rotate(0deg); }
                      }
                  `}
              </style>
              <div className="purchase-header">
                <h1 className="purchase-title">Inquiry</h1>
                <p className="purchase-description">
                If you've browsed our <a href="/purchase">stock</a> and have not found something you like, please submit a custom inquiry via this form.
              Begin by selecting a shape, or add one of your own. Then fill in all the details. Not all details are required, but you must fill in the length and breadth
              If you have a custom request sheet, you can upload the custom excel sheet or PDF via our upload option below.
              </p>
            </div>
            {/* UI elements and logic for selecting shapes, filling inquiry details, and submitting the form */}
            {/* Similar to Purchase.js but adjusted for Inquiry logic */}
            <div className="email-input-container">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter email" className="custom-form-control email-input" value={email} onChange={(e) => setEmail(e.target.value)} />
                <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                </Form.Text>
                </Form.Group>
            </div>
            <Tab.Container defaultActiveKey="shapes">
                <Nav variant="tabs" className="my-custom-tabs">
                    <Nav.Item>
                        <Nav.Link eventKey="shapes">Shapes</Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content>
                <Tab.Pane eventKey="shapes">
                    {/* Shape selection grid */}
                    <div className="d-flex flex-wrap justify-content-center mb-4">
                    {diamondShapes.map((shape, index) => (
                        <div className="shape-container" key={index}>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id={`tooltip-${index}`}>{shape.name.toUpperCase()}</Tooltip>}
                        >
                            <div
                            className={`img-thumbnail ${selectedShapes[shape.name] ? 'selected' : ''}`}
                            onClick={() => toggleShapeSelection(shape.name)}
                            style={{ backgroundImage: `url(${shape.image})` }}
                            />
                        </OverlayTrigger>
                        <div className="shape-name">{shape.name}</div>
                        </div>
                    ))}
                    </div>
                    
                    {/* Custom shape addition section */}
                    <div className="text-center mt-4"> {/* Adjust margin-top as needed */}
                    <p className="mb-2">Can't find the shape you want here?</p> {/* Adjust bottom margin as needed */}
                    <Button variant="secondary" onClick={() => setShowCustomShapeModal(true)}>
                        Add Shape
                    </Button>
                    </div>
                </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
            <br/>
            {/* Inquiry tables for each selected shape */}
            {Object.keys(selectedShapes).filter(shapeName => selectedShapes[shapeName]).map(shapeName => (
                <div key={shapeName} className="custom-card">
                <div className="custom-card-header">{shapeName}</div>
                <div className="custom-card-body">
                  <div className="custom-table-scroll">
                        <Table striped bordered hover size="sm" className="custom-table">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Size</th>
                                    <th>Length</th>
                                    <th>Breadth</th>
                                    <th>Color</th>
                                    <th>Clarity</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                    {inquiries[shapeName] ? inquiries[shapeName].map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                        {/* Dynamic row inputs */}
                                        <td>
                                            <Form.Control as="select" value={row.type} onChange={(e) => handleInquiryChange(shapeName, rowIndex, 'type', e.target.value)}>
                                                <option value="">Select Type</option>
                                                <option value="CVD">CVD</option>
                                                <option value="HPHT">HPHT</option>
                                            </Form.Control>
                                        </td>
                                        <td><Form.Control type="text" value={row.size} onChange={(e) => handleInquiryChange(shapeName, rowIndex, 'size', e.target.value)} /></td>
                                        <td><Form.Control type="text" value={row.length} onChange={(e) => handleInquiryChange(shapeName, rowIndex, 'length', e.target.value)} /></td>
                                        <td><Form.Control type="text" value={row.breadth} onChange={(e) => handleInquiryChange(shapeName, rowIndex, 'breadth', e.target.value)} /></td>
                                        <td><Form.Control type="text" value={row.color} onChange={(e) => handleInquiryChange(shapeName, rowIndex, 'color', e.target.value)} /></td>
                                        <td><Form.Control type="text" value={row.clarity} onChange={(e) => handleInquiryChange(shapeName, rowIndex, 'clarity', e.target.value)} /></td>
                                        <td>
                                            <Button variant="outline-danger" onClick={() => removeInquiryRow(shapeName, rowIndex)}>Remove</Button>
                                        </td>
                                    </tr>
                                )): null}
                            </tbody>
                        </Table>
                        
                    </div>
                    </div>
                    <div>
                    <Button variant="outline-success" className="add-to-cart-button" onClick={() => addInquiryRow(shapeName)}>Add Row</Button>
                    </div>
                    </div>
            ))}
            <div className="submit-file-section">
                <h3><b>OR</b> Upload Custom Sheet</h3>
                <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Upload Your Excel Sheet</Form.Label>
                <Form.Control type="file" accept=".xlsx,.xls,.csv,.numbers" onChange={handleFileChange} />
                <Form.Text className="text-muted">Accepted formats: .xlsx, .xls, .csv, .numbers</Form.Text>
                </Form.Group>
            </div>

            <Form.Label>Anything Else You'd Like Us To Know?</Form.Label>
                <Form.Control type="text" placeholder="Additional Comments" className="custom-form-control email-input" value={additionalComments} onChange={(e) => setAdditionalComments(e.target.value)} />

            {/* Submit Inquiry Button */}
            <div>
            <div className="submit-inquiry-section">
                <Button variant="primary" className="custom-button submit-inquiry-btn" onClick={submitInquiry}>Submit Inquiry</Button>
            </div>
            </div>
            <Modal show={showCustomShapeModal} onHide={() => setShowCustomShapeModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Custom Shape</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Shape Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter custom shape name" value={newCustomShapeName} onChange={(e) => setNewCustomShapeName(e.target.value)} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowCustomShapeModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={addCustomShape}>
                        Add Shape
                    </Button>
                </Modal.Footer>
            </Modal>;
        </Container>
    );
}

export default Inquiry;
