import React from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

function NavBar({ user, userProfileCompleted }) {
  const auth = getAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    auth.signOut().then(() => {
      navigate('/');
    });
  };

  return (
    <Navbar expand="lg">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>Nirvana</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/"><Nav.Link>Home</Nav.Link></LinkContainer>
            <LinkContainer to="/purchase"><Nav.Link>Purchase</Nav.Link></LinkContainer>
            <LinkContainer to="/contact"><Nav.Link>Contact</Nav.Link></LinkContainer>
            <LinkContainer to="/inquire"><Nav.Link>Inquire</Nav.Link></LinkContainer>
          </Nav>
          <Nav>
            {user ? (
              <NavDropdown title="Account" id="basic-nav-dropdown">
                {!userProfileCompleted && (
                  <NavDropdown.Item as={Link} to="/profile-completion" className="custom-dropdown-item">
                    Complete Profile
                  </NavDropdown.Item>
                )}
                <NavDropdown.Item as={Link} to="/account" className="custom-dropdown-item">My Account</NavDropdown.Item>
                <NavDropdown.Item onClick={handleLogout} className="custom-dropdown-item">Logout</NavDropdown.Item>
              </NavDropdown>
            ) : (
              <>
                <LinkContainer to="/login"><Nav.Link>Login</Nav.Link></LinkContainer>
                <LinkContainer to="/signup"><Nav.Link>Sign Up</Nav.Link></LinkContainer>
              </>
            )}
            <LinkContainer to="/cart"><Nav.Link>Cart</Nav.Link></LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
