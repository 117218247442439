import React from 'react';

const CustomCard = ({ header, children, footer, className }) => {
  return (
    <div className={`custom-card ${className}`}>
      {header && <div className="custom-card-header">{header}</div>}
      <div className="custom-card-body">{children}</div>
      {footer && <div className="custom-card-footer">{footer}</div>}
    </div>
  );
};

export default CustomCard;

