import React, { useState, useEffect } from 'react';
import { Container, Card, Table, Button, Alert, Form, Image, Badge, OverlayTrigger, Tooltip, Tab, Nav, Row, Col, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './Purchase.css';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, setDoc, arrayUnion, getDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import '../index.css'
import { debounce } from 'lodash';

function Purchase() {
    const [selectedShapes, setSelectedShapes] = useState({});
    const [volumeInputs, setVolumeInputs] = useState({});
    const [totalPrice, setTotalPrice] = useState(0);
    const navigate = useNavigate();
    const [diamondShapes, setDiamondShapes] = useState([]);
    const [filteredShapes, setFilteredShapes] = useState([]);
    const [sizeRange, setSizeRange] = useState([0, 3]);
    const [priceRange, setPriceRange] = useState([0, 10000]);
    const [lengthRange, setLengthRange] = useState([0, 10]);
    const [breadthRange, setBreadthRange] = useState([0, 10]);
    const [ratioRange, setRatioRange] = useState([0, 5]);
    const [selectedColors, setSelectedColors] = useState(new Set());
    const [selectedClarity, setSelectedClarity] = useState(new Set());
    const auth = getAuth();
    const db = getFirestore();
    const [isUserVerified, setIsUserVerified] = useState(false);
    const [isProfileComplete, setIsProfileComplete] = useState(false);
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user && user.emailVerified) {
          setIsUserVerified(true);
          const docRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists() && docSnap.data().profileCompleted) {
            setIsProfileComplete(true);
          } else {
            setIsProfileComplete(false);
          }
        } else {
          setIsUserVerified(false);
          setIsProfileComplete(false);
        }
      });

      return () => unsubscribe();
    }, []);


    const handleFilterChange = () => {
      let newFilteredShapes = [];

      for (let shape of diamondShapes) {
          let filteredDetails = [];

          for (let detail of shape.details) {
              let pricePerCarat = detail.pricePerCarat;
              let size = detail.size;
              let length = detail.length;
              let breadth = detail.breadth;
  
              if (
                  size >= sizeRange[0] && size <= sizeRange[1] &&
                  pricePerCarat >= priceRange[0] && pricePerCarat <= priceRange[1] &&
                  (selectedColors.size === 0 || selectedColors.has(detail.color)) &&
                  (selectedClarity.size === 0 || selectedClarity.has(detail.clarity)) &&
                  length >= lengthRange[0] && length <= lengthRange[1] &&
                  breadth >= breadthRange[0] && breadth <= breadthRange[1]
              ) {
                  filteredDetails.push(detail);
              }
          }
  
          if (filteredDetails.length > 0) {
              newFilteredShapes.push({
                  ...shape,
                  details: filteredDetails
              });
          }
      }
  
      setFilteredShapes(newFilteredShapes);
  
      const visibleShapeNames = new Set(newFilteredShapes.map(shape => shape.name));
      setSelectedShapes(prevShapes => {
          const updatedSelection = { ...prevShapes };
          Object.keys(prevShapes).forEach(shapeName => {
              if (!visibleShapeNames.has(shapeName)) {
                  updatedSelection[shapeName] = false;
              }
          });
          return updatedSelection;
      });
  };

  const handleFilterChangeDebounced = debounce(handleFilterChange, 200);
  useEffect(() => {
    handleFilterChangeDebounced();
  }, [sizeRange, priceRange, lengthRange, breadthRange, selectedColors, selectedClarity]);
  
  const processRange = (range, type) => {
    switch (type) {
      case "mm":
        return range.replace(/ mm/g, "").split(" - ").map(Number);
      case 'ct':
        return range.replace(/ ct/g, "").split(" - ").map(Number);
      case '$':
        return range.replace(/\$/g, "").split(" - ").map(Number);
      default:
        break;
    }
  };

  useEffect(handleFilterChange, [sizeRange, priceRange, ratioRange, selectedColors, selectedClarity, diamondShapes]);

  const toggleColor = (color) => {
    setSelectedColors(prev => new Set(prev.has(color) ? Array.from(prev).filter(c => c !== color) : prev.add(color)));
    handleFilterChange();
  };

  const toggleClarity = (clarity) => {
    setSelectedClarity(prev => new Set(prev.has(clarity) ? Array.from(prev).filter(c => c !== clarity) : prev.add(clarity)));
    handleFilterChange();
  };

  const handleSliderChange = (type, value) => {
    switch(type) {
        case 'size':
            setSizeRange(value);
            break;
        case 'price':
            setPriceRange(value);
            break;
        case 'length':
          setLengthRange(value);
          break;
        case 'breadth':
          setBreadthRange(value);
          break;
        default:
            break;
    }
    handleFilterChange();
};

  const sizeRanges = ["0 ct - 0.2 ct", "0.2 ct - 0.4 ct", "0.4 ct - 0.6 ct", "0.6 ct - 0.8 ct", "0.8 ct - 1 ct"];
  const handleRangeSelect = (range, type) => {
      const [min, max] = [range[0], range[1]];
      handleSliderChange(type, [min, max]);
  };

  const priceRanges = ["$100 - $150", "$150 - $200", "$200 - $250", "$250 - $300", "$300 - $500", "$500 - $1000"];
  const handlePriceRangeSelect = (range, type) => {
      const [min, max] = [range[0], range[1]];
      handleSliderChange(type, [min, max]);
  };

  const lengthRanges = ["0 mm - 1 mm", "1 mm - 2 mm", "2 mm - 3 mm", "3 mm - 4 mm", "4 mm - 5 mm", "5 mm - 6 mm"];
  const handleLengthRangeSelect = (range, type) => {
      const [min, max] = [range[0], range[1]];
      handleSliderChange(type, [min, max]);
  };

  const breadthRanges = ["0 mm - 1 mm", "1 mm - 2 mm", "2 mm - 3 mm", "3 mm - 4 mm", "4 mm - 5 mm", "5 mm - 6 mm"];
  const handleBreadthRangeSelect = (range, type) => {
      const [min, max] = [range[0], range[1]];
      handleSliderChange(type, [min, max]);
  };

  const uniqueColors = Array.from(new Set(diamondShapes.flatMap(shape => shape.details.map(d => d.color))));
  const uniqueClarity = Array.from(new Set(diamondShapes.flatMap(shape => shape.details.map(d => d.clarity))));

  useEffect(() => {
      const fetchShapesAndImages = async () => {
          setLoading(true); // Start loading
          const storage = getStorage();
          const csvRef = ref(storage, 'stock-data/data.csv');

          try {
              const url = await getDownloadURL(csvRef);
              const response = await fetch(url);
              const text = await response.text();
              const rows = text.split('\n').slice(1);
              let tempShapes = {};

              for (const row of rows) {
                  const columns = row.split(',');
                  if (columns[0] && columns[0].trim().toLowerCase() !== 'cvd') {
                      const type = columns[0].trim();
                      const shapeName = columns[1]?.trim();
                      const imageName = shapeName.replace(/\s/g, '').toLowerCase();
                      const details = {
                          type,
                          stockId: columns[2].trim(),
                          measurements: columns[3]?.trim(),
                          size: parseFloat(columns[4]?.trim()),
                          length: parseFloat(columns[5]?.trim()),
                          breadth: parseFloat(columns[6]?.trim()),
                          ratio: columns[7]?.trim(),
                          color: columns[8]?.trim(),
                          clarity: columns[9]?.trim(),
                          pricePerCarat: parseFloat(columns[10]),
                      };

                      if (!tempShapes[shapeName]) {
                          tempShapes[shapeName] = {
                              name: shapeName,
                              details: [],
                              pricePerCarat: details.pricePerCarat,
                          };

                          try {
                              const imageRef = ref(storage, `diamond-icons/${imageName}-image.png`);
                              const imageUrl = await getDownloadURL(imageRef);
                              tempShapes[shapeName].image = imageUrl;
                          } catch (imageError) {
                              console.error(`Failed to load image for shape ${shapeName}:`, imageError);
                              tempShapes[shapeName].image = '';
                          }
                      }

                      tempShapes[shapeName].details.push(details);
                  }
              }

              const shapesArray = Object.values(tempShapes);
              setDiamondShapes(shapesArray);
          } catch (error) {
              console.error('Failed to fetch shapes and images:', error);
          } finally {
              setLoading(false); // End loading
          }
      };

      fetchShapesAndImages();
  }, []);

  const toggleShapeSelection = (shapeName) => {
      setSelectedShapes(prev => ({
          ...prev,
          [shapeName]: !prev[shapeName]
      }));
  };

  const handleVolumeChange = (stockId, shapeName, size, field, value, carats) => {
    const key = stockId;
    const shape = diamondShapes.find(shape => shape.name === shapeName);
    const update = volumeInputs[key] || {};

    update[field] = parseFloat(value) || 0;
    const numCarats = parseFloat(carats);
    
    if (field === 'totalStones') {
        update.totalCarats = (update.totalStones * numCarats);
    } else {
        update.totalStones = (update.totalCarats / numCarats);
    }
    update.shapeName = shapeName
    update.size = size
    update.totalPriceForShapeSize = update.totalCarats * shape.pricePerCarat;
    setVolumeInputs(prev => ({
        ...prev,
        [key]: update
    }));

    updateTotalPrice();
  };

  useEffect(() => {
      updateTotalPrice();
  }, [volumeInputs]);

  const updateTotalPrice = () => {
      const total = Object.values(volumeInputs)
        .filter(({ selected }) => selected)
        .reduce((acc, { totalPriceForShapeSize }) => acc + totalPriceForShapeSize, 0);
      setTotalPrice(total);
  };

  const addToCartAndNavigate = async () => {
    if (!auth.currentUser) {
        console.error("User not logged in");
        return;
    }

    const selectedItems = Object.entries(volumeInputs).filter(([_, value]) => value.selected).map(([key, value]) => {
        const shape = diamondShapes.find(s => value.shapeName === s.name);
        if (!shape) {
            console.error("Shape not found:", key);
            return null;
        }
        const detail = shape.details.find(s => s.stockId === key);
        if (!detail) {
          console.error("Detail not found:", key);
          return null;
        }
        return {
            diamond_shape: shape.name,
            unit_price: value.totalPriceForShapeSize / value.totalCarats,
            quantity_ordered: value.totalCarats,
            total: value.totalPriceForShapeSize,
            stockId: detail.stockId,
            length: detail.length,
            breadth: detail.breadth
        };
    }).filter(item => item !== null);

    if (selectedItems.length === 0) {
        console.error("No valid items to add to cart.");
        return;
    }

    const userCartRef = doc(db, "userCarts", auth.currentUser.uid);
    try {
        await setDoc(userCartRef, {
            cartItems: arrayUnion(...selectedItems)
        }, { merge: true });
        navigate('/cart');
    } catch (error) {
        console.error("Error updating cart: ", error);
    }
};

const handleDirectInput = (type, isMin, value) => {
  const numericalValue = Number(value);
  switch(type) {
    case 'length':
      setLengthRange(prev => isMin ? [numericalValue, prev[1]] : [prev[0], numericalValue]);
      break;
    case 'breadth':
      setBreadthRange(prev => isMin ? [numericalValue, prev[1]] : [prev[0], numericalValue]);
      break;
    case 'size':
      setSizeRange(prev => isMin ? [numericalValue, prev[1]] : [prev[0], numericalValue]);
      break;
    case 'price':
      setPriceRange(prev => isMin ? [numericalValue, prev[1]] : [prev[0], numericalValue]);
      break;
    default:
      break;
  }
  handleFilterChange(); // Apply the filter after updating the range
};

const resetSelections = () => {
  setSelectedShapes({});
  setVolumeInputs({});
  setTotalPrice(0);
  setSizeRange([0, 10]);
  setPriceRange([100, 10000]);
  setRatioRange([0, 5]);
  setSelectedColors(new Set());
  setSelectedClarity(new Set());
  handleFilterChange(); // Call to reapply filters after reset
};

return (
    <Container className="mt-5">
            <style type="text/css">
                {`
                    .img-thumbnail {
                        width: 75px; /* Smaller width */
                        height: 75px; /* Smaller height to maintain aspect ratio */
                        background-size: contain; /* Contain the background image within the button */
                        background-repeat: no-repeat;
                        background-position: center;
                        margin: 5px; /* Add some space around the buttons */
                    }

                    .img-thumbnail:hover {
                        animation: wiggle 0.5s ease-in-out;
                    }
                    
                    @keyframes wiggle {
                        0% { transform: rotate(-5deg); }
                        25% { transform: rotate(5deg); }
                        50% { transform: rotate(-5deg); }
                        75% { transform: rotate(5deg); }
                        100% { transform: rotate(0deg); }
                    }
                `}
            </style>
            
            {loading ? (
                <div className="text-center">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <>
                  <div className="purchase-header">
                    <h1 className="purchase-title">Explore Our Diamond Collection</h1>
                    <p className="purchase-description">
                    Discover the ultimate diamond shopping experience at Nirvana Jewels. Our purchase page offers an extensive selection of perfectly calibrated HPHT diamonds in various shapes and sizes to suit your unique preferences. Easily browse through our exquisite collection, view transparent pricing, and select your desired diamonds with confidence. Each stone is meticulously measured to ensure flawless precision and unmatched quality. Whether you’re looking to add to your personal collection or find the perfect centerpiece for a special piece of jewelry, Nirvana Jewels makes it effortless to pick, purchase, and own diamonds that truly shine. Start your journey to owning the finest diamonds today.
                    </p>
                  </div>
                  <Button variant="secondary" onClick={resetSelections} className="reset-selection-btn">
                      Reset Selection
                  </Button>
                  <Tab.Container defaultActiveKey="shapes">
                    <Nav variant="tabs" className="my-custom-tabs">
                      <Nav.Item>
                        <Nav.Link eventKey="shapes">Shapes</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="filters">Filters</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="shapes">
                        <div className="d-flex flex-wrap justify-content-center mb-4">
                          {filteredShapes.map((shape, index) => (
                            <div className="shape-container" key={index}>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-${index}`}>
                                    {shape.name.toUpperCase()}
                                  </Tooltip>
                                }>
                                <div
                                  className={`img-thumbnail ${selectedShapes[shape.name] ? 'selected' : ''}`}
                                  onClick={() => toggleShapeSelection(shape.name)}
                                  style={{ backgroundImage: `url(${shape.image})` }}
                                />
                              </OverlayTrigger>
                              <div className="shape-name">{shape.name}</div>
                            </div>
                          ))}  
                          <div className="text-center mt-4"> {/* Adjust margin-top as needed */}
                              <p className="mb-2">Can't find the configuration you want here? Go to our <a href="/inquire">Inquire</a> page to make a custom inquiry</p> {/* Adjust bottom margin as needed */}
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="filters" title="Filters">
                        <Row>
                          <Col md={6} className="filter-section">
                            <div className="filter-container">
                              <h5>Length Range</h5>
                              <div className="slider-with-inputs">
                                <Slider
                                  range
                                  min={0}
                                  max={10}
                                  step={0.01}
                                  value={lengthRange}
                                  onChange={value => handleLengthRangeSelect(value, "length")}
                                  className="custom-slider"
                                />
                                <div className="input-group">
                                  <div>
                                    <input
                                      type="number"
                                      value={lengthRange[0]}
                                      onChange={(e) => handleDirectInput('length', true, e.target.value)}
                                      className="min-input"
                                    />
                                    <span className="unit">mm</span>
                                  </div>
                                  <div>
                                    <input
                                      type="number"
                                      value={lengthRange[1]}
                                      onChange={(e) => handleDirectInput('length', false, e.target.value)}
                                      className="max-input"
                                    />
                                    <span className="unit">mm</span>
                                  </div>
                                </div>
                              </div>
                              <div className="range-buttons">
                                {lengthRanges.map((range, idx) => (
                                  <Button key={idx} variant="outline-secondary" onClick={() => handleLengthRangeSelect(processRange(range, "mm"), 'length')}>
                                    {range}
                                  </Button>
                                ))}
                              </div>
                            </div>
                            <div className="filter-container">
                              <h5>Breadth Range</h5>
                              <div className="slider-with-inputs">
                                <Slider
                                  range
                                  min={0}
                                  max={10}
                                  step={0.01}
                                  value={breadthRange}
                                  onChange={value => setBreadthRange(value)}
                                  className="custom-slider"
                                />
                                <div className="input-group">
                                  <div>
                                    <input
                                      type="number"
                                      value={breadthRange[0]}
                                      onChange={(e) => handleDirectInput('breadth', true, e.target.value)}
                                      className="min-input"
                                    />
                                    <span className="unit">mm</span>
                                  </div>
                                  <div>
                                    <input
                                      type="number"
                                      value={breadthRange[1]}
                                      onChange={(e) => handleDirectInput('breadth', false, e.target.value)}
                                      className="max-input"
                                    />
                                    <span className="unit">mm</span>
                                  </div>
                                </div>
                              </div>
                              <div className="range-buttons">
                                {breadthRanges.map((range, idx) => (
                                  <Button key={idx} variant="outline-secondary" onClick={() => handleBreadthRangeSelect(processRange(range, 'mm'), 'breadth')}>
                                    {range}
                                  </Button>
                                ))}
                              </div>
                            </div>
                            <div className="filter-container">
                              <h5>Size Range</h5>
                              <div className="slider-with-inputs">
                                <Slider
                                  range
                                  min={0}
                                  max={3}
                                  step={0.01}
                                  value={sizeRange}
                                  onChange={value => setSizeRange(value)}
                                  className="custom-slider"
                                />
                                <div className="input-group">
                                  <div>
                                    <input
                                      type="number"
                                      value={sizeRange[0]}
                                      onChange={(e) => handleDirectInput('size', true, e.target.value)}
                                      className="min-input"
                                    />
                                    <span className="unit">ct</span>
                                  </div>
                                  <div>
                                    <input
                                      type="number"
                                      value={sizeRange[1]}
                                      onChange={(e) => handleDirectInput('size', false, e.target.value)}
                                      className="max-input"
                                    />
                                    <span className="unit">ct</span>
                                  </div>
                                </div>
                              </div>
                                <div className="range-buttons">
                                    {sizeRanges.map((range, idx) => (
                                      <Button key={idx} variant="outline-secondary" onClick={() => handleRangeSelect(processRange(range, 'ct'), 'size')}>
                                        {range}
                                      </Button>
                                    ))}
                                  </div>
                              </div>
                              <div className="filter-container">
                              <h5>Price Range</h5>
                              <div className="slider-with-inputs">
                                <Slider
                                  range
                                  min={0}
                                  max={1000}
                                  step={1}
                                  value={priceRange}
                                  onChange={value => setPriceRange(value)}
                                  className="custom-slider"
                                />
                                <div className="input-group">
                                  <div>
                                    <input
                                      type="number"
                                      value={priceRange[0]}
                                      onChange={(e) => handleDirectInput('price', true, e.target.value)}
                                      className="min-input"
                                    />
                                    <span className="unit">per ct</span>
                                  </div>
                                  <div>
                                    <input
                                      type="number"
                                      value={priceRange[1]}
                                      onChange={(e) => handleDirectInput('price', false, e.target.value)}
                                      className="max-input"
                                    />
                                    <span className="unit">per ct</span>
                                  </div>
                                </div>
                              </div>
                                <div className="range-buttons">
                                  {priceRanges.map((range, idx) => (
                                    <Button key={idx} variant="outline-secondary" onClick={() => handlePriceRangeSelect(processRange(range, '$'), 'price')}>
                                      {range}
                                    </Button>
                                  ))}
                                </div>
                              </div>
                            </Col>
                            <Col md={6} className="filter-section">
                              <div className="filter-container">
                                <h5>Color</h5>
                                <div className="checkbox-group">
                                  {uniqueColors.map(color => (
                                    <Form.Check
                                      key={color}
                                      inline
                                      label={color}
                                      type="checkbox"
                                      id={`color-${color}`}
                                      onChange={() => toggleColor(color)}
                                      checked={selectedColors.has(color)}
                                    />
                                  ))}
                                </div>
                              </div>
                              <div className="filter-container">
                                <h5>Clarity</h5>
                                <div className="checkbox-group">
                                  {uniqueClarity.map(clarity => (
                                    <Form.Check
                                      key={clarity}
                                      inline
                                      label={clarity}
                                      type="checkbox"
                                      id={`clarity-${clarity}`}
                                      onChange={() => toggleClarity(clarity)}
                                      checked={selectedClarity.has(clarity)}
                                    />
                                  ))}
                                </div>
                              </div>
                            </Col>
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
              
                  {Object.keys(selectedShapes).filter(shapeName => selectedShapes[shapeName]).map(shapeName => {
                      const shape = filteredShapes.find(shape => shape.name === shapeName);
                      if (!shape) {
                        return null;
                      }
                      return (
                        <div key={shapeName} className="custom-card">
                          <div className="custom-card-header">{shapeName}</div>
                          <div className="custom-card-body">
                            <div className="custom-table-scroll">
                              <Table striped bordered hover size="sm" className="custom-table">
                                  <thead>
                                      <tr>
                                          <th className="sticky-header">Type</th>
                                          <th className="sticky-header">Measurements</th>
                                          <th className="sticky-header">Size</th>
                                          <th className="sticky-header">Length</th>
                                          <th className="sticky-header">Breadth</th>
                                          <th className="sticky-header">Price Per Carat</th>
                                          <th className="sticky-header">Price Per Stone</th>
                                          <th className="sticky-header">Color</th>
                                          <th className="sticky-header">Clarity</th>
                                          <th className="sticky-header">Ratio</th>
                                          <th className="sticky-header">Total Stones</th>
                                          <th className="sticky-header">Total Carats</th>
                                          <th className="sticky-header">Total Price</th>
                                          <th className="sticky-header">Add?</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      {shape.details.map((detail, index) => {
                                          const key = detail.stockId;
                                          const volume = volumeInputs[key] || {};
                                          return (
                                              <tr key={index}>
                                                  <td>{detail.type}</td>
                                                  <td>{detail.measurements}</td>
                                                  <td>{detail.size.toFixed(2)}</td>
                                                  <td>{detail.length.toFixed(2)}</td>
                                                  <td>{detail.breadth.toFixed(2)}</td>
                                                  <td>${detail.pricePerCarat.toFixed(2)}</td>
                                                  <td>${(detail.pricePerCarat * 0.2).toFixed(2)}</td> {/* Assuming this calculation is correct */}
                                                  <td>{detail.color}</td>
                                                  <td>{detail.clarity}</td>
                                                  <td>{detail.ratio}</td>
                                                  {isUserVerified && isProfileComplete ? (
                                                    <>
                                                  <td>
                                                      <Form.Control
                                                          type="number"
                                                          value={volume.totalStones || ''}
                                                          onChange={(e) => handleVolumeChange(detail.stockId, shapeName, [detail.length, detail.breadth], 'totalStones', e.target.value, detail.size)}
                                                          disabled={!volume.selected} />
                                                  </td>
                                                  <td>
                                                      <Form.Control
                                                          type="number"
                                                          value={volume.totalCarats || ''}
                                                          onChange={(e) => handleVolumeChange(detail.stockId, shapeName, [detail.length, detail.breadth], 'totalCarats', e.target.value, detail.size)}
                                                          disabled={!volume.selected} />
                                                  </td>
                                                  <td>${volume.totalPriceForShapeSize?.toFixed(2) || 0}</td>
                                                  <td>
                                                          <Form.Check
                                                            type="checkbox"
                                                            checked={volume.selected || false}
                                                            onChange={() => setVolumeInputs(prev => ({
                                                              ...prev,
                                                              [key]: { ...volume, selected: !volume.selected }
                                                            }))}
                                                          />
                                                      </td>
                                                      </>
                                                  ) :( <td colSpan="4"><b>Log in and complete your profile to add to cart.</b></td>
                                                  )}
                                              </tr>
                                          );
                                      })}
                                  </tbody>
                                  </Table>
                              </div>
                          </div>
                    </div>
                      );
                  })}
                  {filteredShapes.length === 0 && (
                      <Alert variant="secondary" className="text-center">
                          No diamonds match your filters. Please update your filters.
                      </Alert>
                  )}
                  <div className="price-and-cart-container">
                        <div className="total-price-display">
                          Total Price: <span>${totalPrice.toFixed(2)}</span>
                        </div>
                        <div>
                        <Button onClick={addToCartAndNavigate} variant="outline-success" className="add-to-cart-button">
                          Add to Cart
                        </Button>
                        </div>
                  </div>
                </>
            )}
    </Container>
  );
}

export default Purchase;
