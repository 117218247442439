import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Alert } from 'react-bootstrap';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, getDocs } from 'firebase/firestore';
import './Details.css'; // Custom CSS for styling
import { auth, db } from '../firebase-config'; // Import auth and db

function Invoice() {
    const { transactionId } = useParams();
    const [invoiceUrl, setInvoiceUrl] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [authInitialized, setAuthInitialized] = useState(false);

    useEffect(() => {
        const checkAuthState = () => {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    setAuthInitialized(true);
                } else {
                    setError("User not authenticated");
                    setLoading(false);
                }
            });
        };

        checkAuthState();
    }, []);

    useEffect(() => {
        const fetchTransaction = async () => {
            if (!authInitialized) return;

            try {
                const transactionsRef = collection(db, "userTransactions");
                const q = query(transactionsRef, where("transactionId", "==", transactionId));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const doc = querySnapshot.docs[0];
                    const transaction = doc.data();
                    setInvoiceUrl(transaction.invoice);
                } else {
                    setError("Transaction not found.");
                }
            } catch (err) {
                setError("Failed to fetch transaction: " + err.message);
            }
            setLoading(false);
        };

        fetchTransaction();
    }, [transactionId, authInitialized]);

    if (loading) {
        return <Container><Alert variant="info">Loading...</Alert></Container>;
    }

    if (error) {
        return <Container><Alert variant="danger">{error}</Alert></Container>;
    }

    return (
        <Container className="my-4">
            <h2>Invoice</h2>
            {invoiceUrl ? (
                <iframe
                    src={invoiceUrl}
                    style={{ width: '100%', height: '80vh', border: 'none' }}
                    title="Invoice"
                ></iframe>
            ) : (
                <Alert variant="info">No invoice available.</Alert>
            )}
        </Container>
    );
}

export default Invoice;
