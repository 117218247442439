import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Row, Col, ListGroup, ListGroupItem, Alert } from 'react-bootstrap';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, collection, addDoc, doc, getDoc } from 'firebase/firestore';
import './Contact.css';

function Contact() {

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [generalSubmitted, setGeneralSubmitted] = useState(false);

    const auth = getAuth();
    const db = getFirestore();

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // If user is logged in, fetch email from Firestore users collection
                getDoc(doc(db, 'users', user.uid)).then((docSnap) => {
                    if (docSnap.exists()) setEmail(docSnap.data().email);
                });
            }
        });
    }, [db]);



    const handleGeneralInquirySubmit = async (e) => {
        e.preventDefault();
        console.log("General Inquiry:", { email, message });
        setGeneralSubmitted(true);

        // Add document to Firestore
        await addDoc(collection(db, 'userQueries'), {
            email,
            inquiry: false,
            question: message
        });

        setEmail('');
        setMessage('');
        // Reset submission state after a delay
        setTimeout(() => setGeneralSubmitted(false), 3000);
    };
    return (
        <Container className="mt-5">
            <h1 className="text-center mb-3">Contact</h1>
            <p className="text-center mb-4">
                For general inquiries or feedback, please use the contact form on the right. If you're looking for a custom size or shape not offered on our website, you can submit multiple custom inquiries below, and we'll respond within 1 business day.
            </p>
                    <h2>Contact Us</h2>
                    <Form onSubmit={handleGeneralInquirySubmit}>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            <Form.Text className="text-muted">
                                We'll never share your email with anyone else.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formBasicMessage" className="mb-3">
                            <Form.Label>Message</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Your message" value={message} onChange={(e) => setMessage(e.target.value)} />
                        </Form.Group>
                        
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                    {generalSubmitted && <Alert variant="success" className="mt-3">Your message has been sent. Thank you for contacting us!</Alert>}
        </Container>
    );
}



export default Contact;